import React, { useState, useRef, useEffect, useCallback } from 'react';
import './informationBox.css'

import { useSelector, useDispatch } from 'react-redux';
import { meterInfoActions } from '../store/meterInfo';

const InformationBox = ({isWideScreen}) => {
    const dispatch = useDispatch();

    const meterInfo = useSelector(state => state.meterInfo);
    
    const [touchStartY, setTouchStartY] = useState(null);
    const informationBoxRef = useRef(null);

    // 스토어 meter 데이터 초기화
    const getInitialInfoData = () => {
        dispatch(meterInfoActions.reset());
    }

    // 스토어 informationBox 설정
    const setInformationBox = useCallback((informationBox) => {
        dispatch(meterInfoActions.setInformationBox(informationBox));
    }, [dispatch]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (informationBoxRef.current && !informationBoxRef.current.contains(event.target)) {
                setInformationBox(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setInformationBox]);

    const handleTouchStart = (event) => {
        setTouchStartY(event.touches[0].clientY);
    };

    const handleTouchMove = (event) => {
        const touchEndY = event.touches[0].clientY;
        const touchDistance = touchEndY - touchStartY;

        if (touchDistance > 250) {
            setInformationBox(false);
            event.stopPropagation();
        }
    };

    return (
        <div
            ref={informationBoxRef} 
            className={`information-box ${isWideScreen ? "landscape " : ""}${meterInfo.informationBox ? meterInfo.meterId ? 'expanded' : 'expanded little' : ''}`}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}>
            <div className='bar'
             onClick={()=>{ 
                if(!meterInfo.informationBox) {
                    getInitialInfoData();
                }
                setInformationBox(!meterInfo.informationBox);
            }}></div>
            {meterInfo.informationBox ?
                meterInfo.meterId ? 
                <div className='information-group'>
                    <div className={`informaiton ${isWideScreen ? "landscape" : ""}`}>
                        <div className='title'>Parking Meter Number</div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>{meterInfo.meterId}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='title'>Meter Head</div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>{meterInfo.meterHead}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='title'>
                            <div className='title-sub'>Monday - Firday</div>
                            <div className='title-sub-explain'>Rate per Hour</div>
                        </div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>9am - 6pm</td>
                                    <td>Max {meterInfo.tMf9a6p}</td>
                                    <td>{meterInfo.rMf9a6p}</td>
                                </tr>
                                <tr>
                                    <td>6pm - 10pm</td>
                                    <td>Max {meterInfo.tMf6p10}</td>
                                    <td>{meterInfo.rMf6p10}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='title'>
                            <div className='title-sub'>Saturday</div>
                            <div className='title-sub-explain'>Rate per Hour</div>
                        </div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>9am - 6pm</td>
                                    <td>Max {meterInfo.tSa9a6p}</td>
                                    <td>{meterInfo.rMf9a6p}</td>
                                </tr>
                                <tr>
                                    <td>6pm - 10pm</td>
                                    <td>Max {meterInfo.tSa6p10}</td>
                                    <td>{meterInfo.rMf6p10}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='title'>
                            <div className='title-sub'>Sunday</div>
                            <div className='title-sub-explain'>Rate per Hour</div>
                        </div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>9am - 6pm</td>
                                    <td>Max {meterInfo.tSu9a6p}</td>
                                    <td>{meterInfo.rMf9a6p}</td>
                                </tr>
                                <tr>
                                    <td>6pm - 10pm</td>
                                    <td>Max {meterInfo.tSu6p10}</td>
                                    <td>{meterInfo.rMf6p10}</td>
                                </tr>
                            </tbody>
                        </table>
                            {meterInfo.rMisc ?
                            <>
                            <div className='title'>Rate Miscellaneous</div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>{meterInfo.rMisc}</td>
                                    </tr>
                                </tbody>
                            </table>
                            </>
                            :<></>}
                            {meterInfo.tMisc ?
                            <>
                            <div className='title'>Time Limit Miscellaneous</div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>{meterInfo.tMisc}</td>
                                    </tr> 
                                </tbody>
                            </table>
                            </>
                            :<></>}
                            {meterInfo.creditCard ?
                            <>
                            <div className='title'>Credit Card Available</div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>{meterInfo.creditCard}</td>
                                    </tr>
                                </tbody>
                            </table>
                            </>
                            :<></>}
                            {meterInfo.payPhone ?
                            <>
                            <div className='title'>Pay By Phone</div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>{meterInfo.payPhone}</td>
                                    </tr>
                                </tbody>
                            </table>
                            </>
                            :<></>}
                    </div>
                    <div className='brokers'>developed by BROKERS</div>
                </div>
                :
                <div className='information-group'>
                    <div className='init-information'>
                        Click a point and get a parking meter info.
                    </div>
                    <div className='brokers'>developed by BROKERS</div>
                </div>
                :
                <></>
            }
        </div>
    );
};

export default InformationBox;