// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map {
    width: 100vw;
    height: 100vh;
}

.leaflet-container {
    width: 100%;
    height: 100%;
    z-index: 0;
}

.my-location {
    position: fixed;
    left: 50%;
    top: 30px;
    z-index: 1;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    color: #4a4a4a;
    background-color: #FFFFFF;
    padding: 6px 15px 6px 10px;
    border-radius: 60px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 3px;
    font-weight: 500;
    transition: 0.5s ease;
}

.my-location:active {
    background-color: #e9e9e9;
}

.my-location-icon {
    width: 16px;
    height: 16px;
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Component/map.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,eAAe;IACf,SAAS;IACT,SAAS;IACT,UAAU;IACV,cAAc;IACd,gCAAgC;IAChC,cAAc;IACd,yBAAyB;IACzB,0BAA0B;IAC1B,mBAAmB;IACnB,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".map {\n    width: 100vw;\n    height: 100vh;\n}\n\n.leaflet-container {\n    width: 100%;\n    height: 100%;\n    z-index: 0;\n}\n\n.my-location {\n    position: fixed;\n    left: 50%;\n    top: 30px;\n    z-index: 1;\n    margin: 0 auto;\n    transform: translate(-50%, -50%);\n    color: #4a4a4a;\n    background-color: #FFFFFF;\n    padding: 6px 15px 6px 10px;\n    border-radius: 60px;\n    font-size: 14px;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    border: 3px;\n    font-weight: 500;\n    transition: 0.5s ease;\n}\n\n.my-location:active {\n    background-color: #e9e9e9;\n}\n\n.my-location-icon {\n    width: 16px;\n    height: 16px;\n    padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
