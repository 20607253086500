// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  position: fixed;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,YAAY;EACZ,eAAe;EACf;IACE,8BAA8B;EAChC;AACF","sourcesContent":[".App {\n  height: 100vh;\n  height: 100dvh;\n  width: 100vw;\n  position: fixed;\n  @supports (-webkit-touch-callout: none) {\n    height: -webkit-fill-available;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
