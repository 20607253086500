import React, { useState, useEffect } from 'react';
import './App.css';
import Map from  './Component/Map.jsx'
import InformationBox from  './Component/InformationBox.jsx'

function App() {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App">
      <Map isWideScreen={isWideScreen}/>
      <InformationBox isWideScreen={isWideScreen} />
    </div>
  );
}

export default App;
