import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  meterId: "",
  meterHead: "",
  rMf9a6p: "",
  rMf6p10: "",
  rSa9a6p: "",
  rSa6p10: "",
  rSu9a6p: "",
  rSu6p10: "",
  rMisc: "",
  timeInEff: "",
  tMf9a6p: "",
  tMf6p10: "",
  tSa9a6p: "",
  tSa6p10: "",
  tSu9a6p: "",
  tSu6p10: "",
  tMisc: "",
  creditCard: "",
  payPhone: "",
  informationBox: false
};


export const meterInfoSlice = createSlice({
  name: 'meterInfo',
  initialState,
  reducers: {
    save: (state, action) => {
      state.meterId = action.payload.meterId;
      state.meterHead = action.payload.meterHead;
      state.rMf9a6p = action.payload.rMf9a6p;
      state.rMf6p10 = action.payload.rMf6p10;
      state.rSa9a6p = action.payload.rSa9a6p;
      state.rSa6p10 = action.payload.rSa6p10;
      state.rSu9a6p = action.payload.rSu9a6p;
      state.rSu6p10 = action.payload.rSu6p10;
      state.rMisc = action.payload.rMisc;
      state.timeInEff = action.payload.timeInEff;
      state.tMf9a6p = action.payload.tMf9a6p;
      state.tMf6p10 = action.payload.tMf6p10;
      state.tSa9a6p = action.payload.tSa9a6p;
      state.tSa6p10 = action.payload.tSa6p10;
      state.tSu9a6p = action.payload.tSu9a6p;
      state.tSu6p10 = action.payload.tSu6p10;
      state.tMisc = action.payload.tMisc;
      state.creditCard = action.payload.creditCard;
      state.payPhone = action.payload.payPhone;
    },
    reset: (state, action) => {
      state.meterId = initialState.meterId;
      state.meterHead = initialState.meterHead;
      state.rMf9a6p = initialState.rMf9a6p;
      state.rMf6p10 = initialState.rMf6p10;
      state.rSa9a6p = initialState.rSa9a6p;
      state.rSa6p10 = initialState.rSa6p10;
      state.rSu9a6p = initialState.rSu9a6p;
      state.rSu6p10 = initialState.rSu6p10;
      state.rMisc = initialState.rMisc;
      state.timeInEff = initialState.timeInEff;
      state.tMf9a6p = initialState.tMf9a6p;
      state.tMf6p10 = initialState.tMf6p10;
      state.tSa9a6p = initialState.tSa9a6p;
      state.tSa6p10 = initialState.tSa6p10;
      state.tSu9a6p = initialState.tSu9a6p;
      state.tSu6p10 = initialState.tSu6p10;
      state.tMisc = initialState.tMisc;
      state.creditCard = initialState.creditCard;
      state.payPhone = initialState.payPhone;
    },
    setInformationBox: (state, action) => {
      state.informationBox = action.payload;
    }
  }
});

export const meterInfoActions = meterInfoSlice.actions;
export default meterInfoSlice.reducer