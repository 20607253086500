import { useDispatch, useSelector } from "react-redux";

import React, { useEffect, useState, useRef } from 'react';

import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';

import { meterInfoActions } from '../store/meterInfo';

import './map.css';

const Map = ({isWideScreen}) => {

    const meterInfo = useSelector(state => state.meterInfo);
    
    const icon = L.icon({ iconUrl: "pin.png", iconSize: [30, 40] });

    const my_location_icon = L.icon({ iconUrl: "my_location_pin.png", iconSize: [20, 20] });

    const [map, setMap] = useState(null);

    const [bbox, setBbox] = useState(null);

    const [parkingMeters, setParkingMeters] = useState([]);

    const dispatch = useDispatch();

    const myLocationRef = useRef();

    useEffect(() => {
        if (!map) return;

        const bounds =map.target.getBounds();
        setBbox({
            lat1: bounds._southWest.lat,
            lon1: bounds._southWest.lng,
            lat2: bounds._northEast.lat,
            lon2: bounds._northEast.lng
        });
    
        map.target.on("zoomend", function () {
            const newBounds =map.target.getBounds();
            setBbox({
                lat1: newBounds._southWest.lat,
                lon1: newBounds._southWest.lng,
                lat2: newBounds._northEast.lat,
                lon2: newBounds._northEast.lng
            });
        });

        
    }, [map]);

    useEffect(() => {
        if(bbox) {
            const getMeterInfo = () => {
                axios.get('https://opendata.vancouver.ca/api/explore/v2.1/catalog/datasets/parking-meters/records?where=in_bbox(geo_point_2d, ' 
                    + bbox.lat1 + ", " 
                    + bbox.lon1 + ", "
                    + bbox.lat2 + ", "
                    + bbox.lon2 + ")")
                .then((response) => {
                    setParkingMeters(response.data.results);
                })
                .catch((error) => {

                });
            }
            getMeterInfo();
        }
    }, [bbox]);

    const saveMeterInfo = (parkingMeter) => {
        // 스토어 meter 데이터 저장
        dispatch(meterInfoActions.save({
            meterId : parkingMeter.meterid,
            meterHead : parkingMeter.meterhead,
            rMf9a6p : parkingMeter.r_mf_9a_6p,
            rMf6p10 : parkingMeter.r_mf_6p_10,
            rSa9a6p : parkingMeter.r_sa_9a_6p,
            rSa6p10 : parkingMeter.r_sa_6p_10,
            rSu9a6p : parkingMeter.r_su_9a_6p,
            rSu6p10 : parkingMeter.r_su_6p_10,
            rMisc : parkingMeter.rate_misc,
            timeInEff : parkingMeter.timeineffe,
            tMf9a6p : parkingMeter.t_mf_9a_6p,
            tMf6p10 : parkingMeter.t_mf_6p_10,
            tSa9a6p : parkingMeter.t_sa_9a_6p,
            tSa6p10 : parkingMeter.t_sa_6p_10,
            tSu9a6p : parkingMeter.t_su_9a_6p,
            tSu6p10 : parkingMeter.t_su_6p_10,
            tMisc : parkingMeter.time_misc,
            creditCard : parkingMeter.creditcard,
            payPhone : parkingMeter.pay_phone
        }));

        // 스토어 informationBox 설정
        dispatch(meterInfoActions.setInformationBox(true));        

        // 맵 center position 이동
        if (!isWideScreen) {
            // 세로 모드
            map.target.flyTo([parkingMeter.geo_point_2d.lat - 0.0007, parkingMeter.geo_point_2d.lon], 18);
        } else {
            // 가로 모드
            map.target.flyTo([parkingMeter.geo_point_2d.lat, parkingMeter.geo_point_2d.lon + 0.0009], 18);
        }
    }

    useEffect(() => {
        if(isWideScreen) {
            if(meterInfo.informationBox && meterInfo.meterId) {
                myLocationRef.current.style.left="30%";
                return;
            } 
            myLocationRef.current.style.left="50%";
        } else {
            myLocationRef.current.style.left="50%";
        }
    }, [isWideScreen, meterInfo.informationBox, meterInfo.meterId])

    const getNowRatePerHour = (data) => {
        if(data.rate_misc !== null) {
            return data.rate_misc;
        }

        const now = new Date();
        const day = now.getDay();
        const hour = now.getHours();

        // 월 - 금
        if(day >= 1 && day <= 5) {
            // 9am - 6pm
            if(hour >= 9 && hour < 18) {
                return data.r_mf_9a_6p;
            // 6pm - 10pm
            } else if(hour >= 18 && hour < 22) {
                return data.r_mf_6p_10;
            }
        } else if(day === 6) {
            // 9am - 6pm
            if(hour >= 9 && hour < 18) {
                return data.r_sa_9a_6p;
            // 6pm - 10pm
            } else if(hour >= 18 && hour < 22) {
                return data.r_sa_6p_10;
            }
        } else if(day === 0) {
            // 9am - 6pm
            if(hour >= 9 && hour < 18) {
                return data.r_su_9a_6p;
            // 6pm - 10pm
            } else if(hour >= 18 && hour < 22) {
                return data.r_su_6p_10;
            }
        }

        return "Please check the meter";
    }
    
    const [myLocation, setMyLocation] = useState(null);
    const findMyLocation = () => {
        map.target.locate().on("locationfound", function (e) {
            map.target.flyTo(e.latlng, 18);
            setMyLocation(<Marker className="my-location-icon" position={[e.latlng.lat, e.latlng.lng]} icon={my_location_icon}></Marker>);
        });
    
    }

    return (
        <div className="map">
        <button ref={myLocationRef} className="my-location" onClick={findMyLocation}>
            <img className="my-location-icon" alt="my-lcation-icon" src={process.env.PUBLIC_URL + '/my_location.png'}/> 
            <div>Your Location</div>
        </button>
        <MapContainer center={[49.2829,-123.1254]} zoom={14} scrollWheelZoom={true} whenReady={setMap}>
        <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {parkingMeters.map((parkingMeter, index) => {
            return (
                <Marker
                    key={index} 
                    position={[parkingMeter.geo_point_2d.lat, parkingMeter.geo_point_2d.lon]}
                    icon={icon}
                    eventHandlers={{
                        click: () => {
                            saveMeterInfo(parkingMeter);
                        },
                    }}>
                        <Tooltip
                            direction="top"
                            style={{background: "white"}}
                            permanent>{getNowRatePerHour(parkingMeter)}</Tooltip>
                </Marker>
            );
        })}
        {myLocation}
        </MapContainer>
        </div>
    );
};

export default Map;